import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Navigation from "../../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ReactGA from "react-ga";
import { Link } from "gatsby";
import DonationFooter from "../../components/donation-footer";
import CodeSnippet from "../../components/code-snippet";

class View extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
	}

	render() {
		return (
			<CodeSnippet
				header={"Dynamically Allocate Matrix in C | A Helpful Line-by-Line Code Tutorial"}
				subHeader={""}
				description={
					"Dynamically Allocate Matrix in C"
				}
				keywords={[]}
				embeddedVideo={""}
				embeddedCode={
					"https://gist.githubusercontent.com/graphoarty/e7c8cc8600836c9c82df64665339719c/raw/e534436bf0695ad8055dc9e4405bfef6c0f14669/dynamically-allocate-matrix-in-c.c"
				}
			/>
		);
	}
}

export default View;
